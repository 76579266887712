@font-face {
  font-family: "light";
  src: url(./assets/fonts/Poppins-Light.ttf);
}

@font-face {
  font-family: "regular";
  src: url(./assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "italic";
  src: url(./assets/fonts/Poppins-Italic.ttf);
}

@font-face {
  font-family: "medium";
  font-weight: 500;
  src: url(./assets/fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: "semi";
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: "bold";
  src: url(./assets/fonts/Poppins-Bold.ttf);
}

.container {
  width: 100% !important;
  max-width: none !important;
  padding-left: 96px;
  padding-right: 96px;
  
}

@media (max-width: 1000px) {
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }
}